<template>
  <div v-if="!this.supplierId"></div>
  <div v-else class="index">
    <div class="index_main">
      <div class="index_banner">
        <van-image width="100%" height="100%" :src="require('@/assets/imgs/banner.png')" />
        <van-notice-bar left-icon="volume-o" scrollable background="#fff" text="各类会员充值优惠进行中。" />
      </div>
      <div v-for="item in goodList" :key="item.categoryId">
        <div class="index_title">
          <span class="shiping">{{item.categroyName}}</span>
        </div>
        <van-grid :gutter="10">
          <van-grid-item v-for="value in item.goods" :key="value.id" icon="icon-shipin" @click="clickitem(value)">
            <div class="index_goodslist_item_img">
              <van-image width="4em" height="4em" :src="value.showImage" />
              <div class="wrap_left_bottom"></div>
              <div class="wrap_right_bottom"></div>
            </div>
            <div class="van-grid-item-name">
              <div class="wrap_left_top"></div>
              <div class="wrap_right_top"></div>
              <p>{{value.name}}</p>
            </div>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <div class="idnex_foot">
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o">首页</van-tabbar-item>
        <van-tabbar-item icon="service-o" @click="service">客服</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import { api_goodlist } from "@/api/api.js"
import { getQueryVariable } from "@/util/utils.js"
export default {
  data () {
    return {
      value: "",
      active: 0,
      goodList: [],
      supplierId: "",
    }
  },
  created () {
    if (sessionStorage.getItem('key') != null) {
      sessionStorage.removeItem('key')
      this.supplierId = this.$route.query.supplierId
    } else {
      this.name = getQueryVariable('name')
      this.goodsId = getQueryVariable("id")
      this.supplierId = getQueryVariable("supplierId")
    }
    this.getGoodList()
  },
  methods: {
    getGoodList () {
      const params = {
        supplierId: this.supplierId
      }
      api_goodlist(params).then(res => {
        if (res.data.code == 0) {
          this.goodList = res.data.data
        }
      })
    },
    clickitem (val) {
      sessionStorage.setItem('key', 'value')
      this.$router.push({
        path: '/recharge',
        query: {
          supplierId: this.supplierId,
          id: val.id,
          page:'1'
        }
      })
    },
    service () {
      this.$router.push({
        path: '/service',
        query: {
          supplierId: this.supplierId,
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.index {
  height: 100%;
  overflow: hidden;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .index_banner {
    padding: 1em 1em;
    .van-notice-bar {
      border-radius: 2em;
      margin-top: 1em;
    }
  }
  .index_main {
    height: 100%;
    overflow: scroll;
    margin-bottom: 1em;
    .index_title {
      margin: 1.8em 0 1.8em 0.8em;
      .shiping {
        height: 1.5em;
        font-size: 1.5em;
        font-weight: 300;
        color: #000000;
        line-height: 1em;
        margin-left: 0.5em;
      }
    }
    .van-grid {
      ::v-deep .van-grid-item__content {
        padding: 0.5em 0 0 0;
      }
      .index_goodslist_item_img {
        width: 100%;
        height: 6em;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .wrap_left_bottom {
          left: -1px;
          bottom: -1px;
          border-radius: 0 1em 0 0;
          position: absolute;
          width: 1em;
          height: 1em;
          z-index: 1;
          background: #f8f8f8;
        }
        .wrap_right_bottom {
          right: -1px;
          bottom: -1px;
          border-radius: 1em 0 0 0;
          position: absolute;
          width: 1em;
          height: 1em;
          z-index: 1;
          background: #f8f8f8;
        }
      }
      .van-grid-item-name {
        width: 100%;
        line-height: 3em;
        position: relative;
        .wrap_left_top {
          left: -1px;
          top: -1px;
          border-radius: 0 0 1em 0;
          position: absolute;
          width: 1em;
          height: 1em;
          z-index: 1;
          background: #f8f8f8;
        }
        .wrap_right_top {
          right: -1px;
          top: -1px;
          border-radius: 0 0 0 1em;
          position: absolute;
          width: 1em;
          height: 1em;
          z-index: 1;
          background: #f8f8f8;
        }
        p {
          font-size: 1.2em;
          text-align: center;
          color: #333;
          border-top: 0.2em dashed #f8f8f8;
        }
      }
    }
  }
  .idnex_foot {
    height: 5em;
    .van-tabbar {
      height: 5em;
    }
    ::v-deep .van-tabbar--fixed {
      position: relative;
    }
  }
}
</style>

